@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property; }

@mixin border-radius($property) {
  border-radius: $property;
  -webkit-border-radius: $property;
  -moz-border-radius: $property;
  -ms-border-radius: $property;
  -o-border-radius: $property; }

@mixin transition($property) {
  -webkit-transition: $property;
  -o-transition: $property;
  transition: $property; }

@mixin flexWidth($property) {
  flex: 0 0 $property;
  -ms-flex: 0 0 $property;
  max-width: $property; }

@mixin d-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

@mixin object-fit {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center; }

@mixin animation($property) {
  animation: $property;
  -webkit-animation: $property;
  -moz-animation: $property; }

@mixin font($size, $weight, $transform) {
  font-size: $size;
  font-weight: $weight;
  text-transform: $transform; }

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @-moz-keyframes #{$name} {
    @content; }
  @-ms-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }

@mixin placeholder($value) {
  &::-webkit-input-placeholder {
    color: $value; }
  &::-moz-placeholder {
    color: $value; }
  &:-ms-input-placeholder {
    color: $value; }
  &:-moz-placeholder {
    color: $value; } }

@mixin color($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color; }

@mixin gradient-one {
  background-image: -moz-linear-gradient( 86deg, rgb(236,3,139) 0%, rgb(251,100,104) 44%, rgb(251,185,54) 100%);
  background-image: -webkit-linear-gradient( 86deg, rgb(236,3,139) 0%, rgb(251,100,104) 44%, rgb(251,185,54) 100%);
  background-image: -ms-linear-gradient( 86deg, rgb(236,3,139) 0%, rgb(251,100,104) 44%, rgb(251,185,54) 100%); }

@mixin gradient-two {
  background-image: -moz-linear-gradient( 45deg, rgb(215,61,245) 0%, rgb(143,61,245) 100%);
  background-image: -webkit-linear-gradient( 45deg, rgb(215,61,245) 0%, rgb(143,61,245) 100%);
  background-image: -ms-linear-gradient( 45deg, rgb(215,61,245) 0%, rgb(143,61,245) 100%); }

@mixin gradient-three {
  background-image: -moz-linear-gradient( 7deg, rgb(236,19,121) 0%, rgb(108,0,146) 100%);
  background-image: -webkit-linear-gradient( 7deg, rgb(236,19,121) 0%, rgb(108,0,146) 100%);
  background-image: -ms-linear-gradient( 7deg, rgb(236,19,121) 0%, rgb(108,0,146) 100%); }

@mixin text-gradient {
  background: #4380e4;
  background: -webkit-linear-gradient(to right, #8446f7, #4380e4);
  background: linear-gradient(to right, #8446f7, #4380e4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }



