/* button css start */
.cmn-btn {
  padding: 15px 35px;
  @include font(18px, weight("semi"), uppercase);
  @include border-radius(999px);
  @include gradient-one;
  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
  @include transition(background-size 0.3s);
  color: #ffffff;
  @media (max-width: 991px) {
    font-size: 16px; }
  @media (max-width: 767px) {
    font-size: 14px; }
  &:hover {
    background-size: 300%;
    color: #ffffff; }
  &.style--two {
    @include gradient-two;
    box-shadow: -1.113px 7.922px 16px 0px rgba(143, 61, 245, 0.63); }
  &.style--three {
    @include gradient-three;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35); }
  &.btn--sm {
    padding: 10px 22px;
    font-size: 14px; } }
.btn-border {
  padding: 12px 35px;
  @include font(16px, weight("semi"), capitalize);
  color: $para-color;
  @include border-radius(999px);
  border: 1px solid rgba(#ffffff, 0.25); }
.btn-grp {
  @include d-flex;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
  *[class*="btn"] {
    margin: 5px 10px;
    align-items: center;
    &.d-flex {
      padding: 8px 35px; } } }
*[class*="btn"] {
  .btn-sm {
    padding: 6px 20px; } }
/* button css end */
